var cookieComplianceEnabled = true;

const registerBtn = document.querySelector('#signupSubmitBtn');

if (registerBtn) {
    registerBtn.addEventListener('click', handleOnSubmit);
}

function handleOnSubmit(targetEl) {
    var result = checkAgreementsTabRequirements()

    if (result.displayTermsAlert) {
        alert('Aby kontynuować, musisz zaakceptować wszystkie umowy użytkownika. Przejrzyj i zaakceptuj umowy, a następnie spróbuj ponownie.')
    }

    if (result.displayCaptchaAlert) {
        alert('Aby kontynuować, musisz ukończyć reCAPTCHA.')
    }

    if (result.isSubmittable && cookieComplianceEnabled) {
        setEUCompCookie()
    }

    return result.isSubmittable
}

function isPasswordValid() {
    var password = $('.password');
    var passwordValue = password.val();
    var passwordLength = passwordValue.length;

    if (passwordLength < 8 || passwordLength > 16) {
        alert("Hasło musi mieć od 8 do 16 znaków");
        return false;
    } else if (!hasLowerCase(passwordValue)
        || !hasUpperCase(passwordValue)
        || (!hasNumber(passwordValue) && !hasSpecialCharacter(passwordValue))) {
        alert("Hasło musi zawierać co najmniej jedną wielką literę, małą literę oraz cyfrę lub znak specjalny");
        return false;
    } else {
        var strengthOptions = {message: "Hasło musi być rzadkie i trudne do odgadnięcia (np. nie H@sło1)"};
        return password.passwordStrength().passesPasswordStrength(strengthOptions);
    }
}

function checkPasswordValidity() {
    var pwInput = $('.password'),
        passwordValue = pwInput.val(),
        passwordLength = passwordValue.length,
        requirementResults = {
            strength: {},
            length: {valid: true},
            upperCase: {valid: true},
            lowerCase: {valid: true},
            specialChars: {valid: true},
        },
        strengthMap = {
            Poor: "Słaby",
            Weak: "Słaby",
            Fair: "Niewystarczające",
            Good: "Dobry",
            Excellent: "Doskonały",
        },
        suggestionsMap = {
            "Use a few words, avoid common phrases": "Use a few words, avoid common phrases",
            "No need for symbols, digits, or uppercase letters": "No need for symbols, digits, or uppercase letters",
            "Add another word or two. Uncommon words are better.": "Add another word or two. Uncommon words are better.",
            "Use a longer keyboard pattern with more turns": "Use a longer keyboard pattern with more turns",
            "Avoid repeated words and characters": "Avoid repeated words and characters",
            "Avoid sequences": "Avoid sequences",
            "Avoid recent years": "Avoid recent years",
            "Avoid years that are associated with you": "Avoid years that are associated with you",
            "Avoid dates and years that are associated with you": "Avoid dates and years that are associated with you",
            "Capitalization doesn't help very much": "Capitalization doesn't help very much",
            "All-uppercase is almost as easy to guess as all-lowercase": "All-uppercase is almost as easy to guess as all-lowercase",
            "Reversed words aren't much harder to guess": "Reversed words aren't much harder to guess",
            "Predictable substitutions like '@' instead of 'a' don't help very much": "Predictable substitutions like '@' instead of 'a' don't help very much"
        },
        warningsMap = {
            'Straight rows of keys are easy to guess': "Straight rows of keys are easy to guess",
            'Repeats like "aaa" are easy to guess': "Repeats like aaa are easy to guess",
            "Sequences like abc or 6543 are easy to guess": "Sequences like abc or 6543 are easy to guess",
            "Recent years are easy to guess": "Recent years are easy to guess",
            "Dates are often easy to guess": "Dates are often easy to guess",
            'This is a top-10 common password': "This is a top-10 common password",
            'This is a top-100 common password': "This is a top-100 common password",
            'This is a very common password': "This is a very common password",
            'This is similar to a commonly used password': "This is similar to a commonly used password",
            'A word by itself is easy to guess': "A word by itself is easy to guess",
            'Names and surnames by themselves are easy to guess': "Names and surnames by themselves are easy to guess",
            'Common names and surnames are easy to guess': "Common names and surnames are easy to guess"
        },
        defaultWarning = "Password must be uncommon and not easily guessable (ex. not P@ssword1)",
        defaultSuggestions = [
            "Use a few words, avoid common phrases",
            "Avoid simple sequences like abc, 456, asdf, etc",
            "Predictable substitutions like '@' instead of 'a' don't help very much",
            "Avoid dates and years that are associated with you"
        ]

    if (passwordLength < 8 || passwordLength > 16) {
        requirementResults.length.valid = false
    }

    if (!hasLowerCase(passwordValue)) {
        requirementResults.lowerCase.valid = false
    }

    if (!hasUpperCase(passwordValue)) {
        requirementResults.upperCase.valid = false
    }

    if (!hasNumber(passwordValue) && !hasSpecialCharacter(passwordValue)) {
        requirementResults.specialChars.valid = false
    }

    var strengthResults = pwInput.getPasswordStrengthResult()

    var suggestions = strengthResults.details.feedback.suggestions,
        translatedSuggestions = [],
        translatedWarning = ''

    if (passwordLength > 0) {
        for (var i = 0; i < suggestions.length; i++) {
            var translatedSuggestion = suggestionsMap[suggestions[i]]

            if (translatedSuggestion) {
                translatedSuggestions.push(translatedSuggestion)
            }
        }

        translatedWarning = warningsMap[strengthResults.details.feedback.warning]
    }

    strengthResults.details.feedback.suggestions = translatedSuggestions.length ? translatedSuggestions : defaultSuggestions

    strengthResults.details.feedback.warning = translatedWarning ? translatedWarning : defaultWarning

    strengthResults.indicatorData.message = strengthMap[strengthResults.indicatorData.message]

    requirementResults.strength = strengthResults

    return requirementResults
}

function hasNumber(text) {
    return /\d/.test(text);
}

function hasLowerCase(text) {
    return /[a-z]/.test(text);
}

function hasUpperCase(text) {
    return /[A-Z]/.test(text);
}

function hasSpecialCharacter(text) {
    return /[^a-zA-Z0-9\s]/.test(text);
}

var hasComplied = false;

$(document).ready(function () {
    $("#loginForm").submit(handleComplianceSubmit);
});

function handleComplianceSubmit() {
    if (hasComplied) {
        setEUCompCookie();
        removeCompliance();
    } else {
        showComplianceRequiredAlert();
    }

    return hasComplied;
}

function checkComplied() {
    if ($("#privacyCheckbox").is(":checked") &&
        $("#cookieCheckbox").is(":checked")) {

        hasComplied = true;
        setEUCompCookie();
        removeCompliance();
    } else {
        showComplianceRequiredAlert();
    }
    return hasComplied;
}

function setEUCompCookie() {
    var expireDate = new Date();
    expireDate.setDate(expireDate.getDate() + 365);
    document.cookie = "EUcomp = 1; path = /; expires=" + expireDate.toUTCString();
}

function showComplianceRequiredAlert() {
    //alert('You must agree to the privacy policy and accept cookies to use the application');

    let cookieComplianceContainer = document.getElementById("cookieCompliance")
    if (cookieComplianceContainer) {
        cookieComplianceContainer.scrollIntoView({
            behavior: "smooth",
            block: "start"
        });
    }
}

function removeCompliance() {
    $("#cookieCompliance").remove();
}
