const loginForm = document.querySelector("#loginForm");
const cookieConsent = document.querySelector("#cookieCheckbox");

if (loginForm) {
    loginForm.addEventListener('submit', async (e) => {
        //e.preventDefault();
        if (!cookieConsent.checked) {
            const info = document.querySelector('#info');
            info.innerHTML = 'Prosimy zaakceptować politykę cookie.';
            return;
        }

        await login(e);
    });
}

const registerForm = document.querySelector("#registerForm");
if (registerForm) {
    registerForm.addEventListener('submit', async (e) => {
        e.preventDefault();
        await register(e);
    });
}

function loginToPublisher(elem) {
    elem.querySelector('#UserEmail').value = document.querySelector('.login-content-container #userEmail').value;
    elem.querySelector('#UserPassword').value = document.querySelector('.login-content-container #userPassword').value;
    elem.querySelector('#loginButton').click();
}

async function login(e) {
    const data = {
        email: e.target.querySelector('#userEmail').value,
        password: e.target.querySelector('#userPassword').value
    }

    const response = await fetch('/api/connector/login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify(data)
    });

    const json = await response.json();

    if (response.status === 200) {
        const elem = document.getElementById('shadowLogin');
        elem.innerHTML = json.form;
        loginToPublisher(elem);
    } else {
        const info = document.querySelector('#info');
        info.innerHTML = json.message;
    }
}

async function register(e) {
    const data = new FormData(e.target);

    const response = await fetch('/api/connector/register', {
        method: 'POST',
        headers: {
            'Accept': 'application/json'
        },
        body: data
    });

    const json = await response.json();

    const info = document.querySelector('#info');

    if (response.status === 200) {
        document.querySelector("#registerForm").style.display = 'none';
        document.querySelector("#thankYouMessage").style.display = 'block';
        document.querySelector("#thankYouMessage > .info").innerHTML =  json.message;

    } else {
        info.innerHTML =  json.message;
    }
}
