function setEUCompCookie() {
    const expireDate = new Date();
    expireDate.setDate(expireDate.getDate() + 365);
    document.cookie = "EUcomp = 1; path = /; expires=" + expireDate.toUTCString() + "; domain=.performers.pl";
}

const submitButton = document.querySelector('#loginButton');

if (submitButton) {
    submitButton.addEventListener('click', setEUCompCookie);
}
